/* ==========================================================================
     Header
	 ========================================================================== */

/* ==========================================================================
     Top Nav & Drop-down
	 ========================================================================== */


// .nmis-navbar {
//   background-color: $blue-primary;
//   li a {
//     color: $white;
//   }
//   .dropdown-menu {
//     min-width: 325px;
//     background-color: $blue-primary;
//     .dropdown-item,
//     .nav-item {
//       background-color: $blue-primary;
//       color: $white;
//       &:hover {
//         text-decoration: underline;
//       }
//     }
//   }
//   .nav-link {
//     margin-top: $margin * 3;
//     &:hover {
//       text-decoration: underline;
//     }
//   }
//   .nav-item a img {
//     padding: $padding $padding * 3 $padding $padding/4;
//   }
//   .navbar-toggler {
//     border-color: $white;
//   }

//   .navbar-toggler-icon {
//     background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
//   }
// }

/* ==========================================================================
     Head area
	 ========================================================================== */
.nmis-header-container {
  margin-bottom: -7px;
}
.nmis-header-container,
.nmis-logo-container {
  position: relative;
  overflow: hidden;
  img {
    width: 100%;
  }
}
.nmis-logo-container {
  position: absolute;
}
.rowrandomise {
  position: relative;
  .nmis-logo {
    z-index: 3;
    img {
      width: 100%;
    }
  }
  .nmis-banner {
    z-index: 1;
    float: left;
    left: 0px;
    width: 100%;
    height: auto;
    img {
      width: 100% !important;
      height: auto !important;
    }
  }

  .mobile-logo li {
    list-style-type: none;
  }
  .nmis-logo-container {
    img {
      width: 100%;
      height: 100%;
      padding: 26px;
    }
    // clip-path: polygon(0 0, 80% 0%, 30% 100%, 0% 100%);
    opacity: 0.9;
    z-index: 4;
  }
}
// .strapline-container {
//   display: inline-block;
//   top: -92px; //fix this
//   margin-bottom: -92px; //fix this
//   z-index: 2;
//   bottom: 0;
//   width: 100%;
//   background-color: $blue-primary;
//   margin-bottom: $margin;
//   h1 {
//     font-size: $h3-font-size;
//     color: $white;
//     text-decoration: none;
//     margin-top: 0 !important;
//     padding: $padding 0;
//   }
//   .nmis-logo-mobile {
//     position: absolute;
//     z-index: 5;
//   }
// }
.mobile-logo {
  float: right;
}
// .header-summary {
//   max-width: 1055px;
//   width: 100%;
// }

// @include media-breakpoint-down(xl) {
//   .strapline {
//     left: 0;
//     h1 {
//       font-size: $h3-font-size;
//       padding: $padding;
//     }
//   }
//   .header-summary {
//     padding: $padding/1.5;
//   }
// }

/*lg = Large ≥992px. Max container width 960px.*/
@include media-breakpoint-down(lg) {
  // .strapline-container {
  //   .strapline {
  //     width: 100%;
  //   }
  // }
  img {
    width: auto;
    height: auto;
  }
  .nmis-banner {
    img {
      width: 100% !important;
      height: auto !important;
    }
  }
}
/* md = Medium ≥768px. Max container width 720px. */
@include media-breakpoint-down(md) {
  .nmis-navbar .nav-link {
    margin-top: 0;
  }
  .nmis-banner {
    img {
      width: 100%;
      height: auto;
    }
  }
}

/*sm = Small ≥576px. Max container width 540px.*/

@include media-breakpoint-down(sm) {
  .nmis-banner {
    img {
      width: 100%;
      height: auto;
    }
  }

  .nmis-logo-container {
    img {
      display: none;
    }
  }
}
/* xs = Extra small <576px. Max container width None (auto) */
@include media-breakpoint-down(xs) {
  // .header-summary {
  //   margin: $margin * 1.5 0 0 0;
  //   padding: $padding/1.5;
  // }
  // .strapline-container {
  //   margin-bottom: -25px;
  // }

  // .strapline {
  //   float: left;
  //   width: 100%;
  //   padding-left: 20px;
  //   h1 {
  //     font-size: $h3-font-size;
  //     padding: $padding/2;
  //   }
  //   .text-center {
  //     float: left !important;
  //   }
  // }

  .nmis-banner {
    img {
      width: 100%;
      height: auto;
    }
  }
}
