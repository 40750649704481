 /* ==========================================================================
     Footer
     ========================================================================== */
 footer {
     bottom: 0;
 }

 .page-footer,
 .page-footer-hp {
     background-color: $blue-primary;
     color: $white;
     border-top: 5px solid $gray-900;
    //  margin-top: $margin*2;

     img {
         width: auto;
     }

     hr {
         border-top: 0;
         margin: $margin/2;
     }

     h4 {
         @extend %nmis-heading-white;
     }

     ul {
         list-style-type: none;
         padding-left: 0;
     }

     p {
         color: $white;
     }

     a {
         color: $white;
         text-decoration: underline;

         &:hover {
             text-decoration: none;
         }
     }

     ul {
         li {
             @extend %bullets;
             white-space: normal;
         }
     }
 }

 .footer-inner {
     width: 942px;
     margin-top: $margin*2;
 }


 /*lg = Large ≥992px. Max container width 960px.*/
 @include media-breakpoint-down(lg) {
     .footer-inner {
         width: 100%;
     }
 }


 /* xs = Extra small <576px. Max container width None (auto) */
 @include media-breakpoint-down(xs) {
     .col-md-4 img {
         padding-bottom: $padding;
     }

     .footer-container {
         padding: 0 $padding*1.75;
     }
 }