@import "../../node_modules/bootstrap/scss/forms";

.form-group {
    label {
        font-weight: bold;
    }
}

.form-group.required {
    label::after {
        content: "*";
        color: $pink;
    }
}
