// 
// Alert 
// 

@import "../../node_modules/bootstrap/scss/pagination";

.page-link {
    margin-right: 10px;
    border: none;
    cursor: pointer;
}