/* ==========================================================================
     Base
     ========================================================================== */
// body * {
//   font-family: $font-family-base;
//   // margin: 0;
//   // padding: 0;
// }
// .nmis-container {
//   min-height: calc(100vh - 228px);
// }
h1 {
  @extend %nmis-heading-h1;
}
h2 {
  @extend %nmis-heading-h2;
}
h3 {
  @extend %nmis-heading-h3;
}
h4 {
  @extend %nmis-heading-h4;
}
// p,
// p a,
// li,
// ul {
//   color: $blue-primary-9000;
// }
// footer .fas {
//   color: white;
// }
// .container-fluid-white {
//   background-color: $white;
// }
// .container-fluid-grey {
//   background-color: $gray-100;
// }
h1, h2, h3, h4, h5, h6, p {
  padding: 0;
  padding-bottom: 1rem; ///2 0;
}
p a {
  color: $blue-primary-900;
  font-weight: bold;
  text-decoration: underline;
  &:hover {
    @extend %linkhoverText;
  }
}

// .bg-blue-secondary {
//   h1, h2, h3, h4, h5, h6, p {
//     color: $white !important;
//   }
// }

// .form-control {
//   border-radius: 0 !important;
// }
// .randomise:nth-child(n + 2) {
//   display: none;
// }
ul.logo {
  margin: $margin * 1.5 0;
  li {
    @extend %bullets;
  }
}
// .container-fluid {
//   padding-right: 0;
//   padding-left: 0;
//   margin-right: auto;
//   margin-left: auto;
//   // @include clearfix;
//   .row {
//     margin-right: 0;
//     margin-left: 0;
//   }
// }
// .container-fluid > {
//   padding-right: $padding * 2;
//   padding-left: $padding * 2;
//   margin-right: auto;
//   margin-left: auto;
//   margin-top: -15px;
//   // @include clearfix;
//   h1 {
//     margin-bottom: $margin;
//   }
// }
// .container-fluid > .nmis-general-fixed {
//   max-width: 700px;
//   padding-right: 0;
//   padding-left: 0;
//   margin-right: auto;
//   margin-left: auto;
//   // content: "";
//   // display: table;
//   // clear: both;
//   ul {
//     padding: $padding $padding * 3;
//     li::before {
//       color: $blue-primary;
//       font-weight: bold;
//       font-size: 16px;
//       display: inline-block;
//       list-style: square outside none;
//     }
//   }
// }
// /*xl = Extra large ≥1200px. Max container width 1140px. */
// @include media-breakpoint-down(xl) {
// }
// /*lg = Large ≥992px. Max container width 960px.*/
// @include media-breakpoint-down(lg) {
//   .container-fluid > .nmis-general-fixed,
//   .container-fluid > {
//     padding: $padding/2 $padding $padding * 2 $padding;
//   }
// }
// /* md = Medium ≥768px. Max container width 720px. */
// @include media-breakpoint-down(md) {
// }
// /*sm = Small ≥576px. Max container width 540px.*/

// @include media-breakpoint-down(sm) {
// }
// /* xs = Extra small <576px. Max container width None (auto) */
// @include media-breakpoint-down(xs) {
//   body {
//     overflow-x: hidden !important;
//   }
//   .container {
//     max-width: 100% !important;
//     overflow-x: hidden !important;
//     // padding-left: 0;
//   }
// }
