// 
// Alert 
// 

@import "../../node_modules/bootstrap/scss/alert";

.alert {
    p {
        &:last-child {
            margin-bottom: 0;
            padding-bottom: 0;
        }
    }
}