// // Bootstrap functions
// //
// // Utility mixins and functions for evalutating source code across our variables, maps, and mixins.

// // Ascending
// // Used to evaluate Sass maps like our grid breakpoints.
// @mixin _assert-ascending($map, $map-name) {
//   $prev-key: null;
//   $prev-num: null;
//   @each $key, $num in $map {
//     @if $prev-num == null {
//       // Do nothing
//     } @else if not comparable($prev-num, $num) {
//       @warn "Potentially invalid value for #{$map-name}: This map must be in ascending order, but key '#{$key}' has value #{$num} whose unit makes it incomparable to #{$prev-num}, the value of the previous key '#{$prev-key}' !";
//     } @else if $prev-num >= $num {
//       @warn "Invalid value for #{$map-name}: This map must be in ascending order, but key '#{$key}' has value #{$num} which isn't greater than #{$prev-num}, the value of the previous key '#{$prev-key}' !";
//     }
//     $prev-key: $key;
//     $prev-num: $num;
//   }
// }

// // Starts at zero
// // Another grid mixin that ensures the min-width of the lowest breakpoint starts at 0.
// @mixin _assert-starts-at-zero($map) {
//   $values: map-values($map);
//   $first-value: nth($values, 1);
//   @if $first-value != 0 {
//     @warn "First breakpoint in `$grid-breakpoints` must start at 0, but starts at #{$first-value}.";
//   }
// }

// // Replace `$search` with `$replace` in `$string`
// // Used on our SVG icon backgrounds for custom forms.
// //
// // @author Hugo Giraudel
// // @param {String} $string - Initial string
// // @param {String} $search - Substring to replace
// // @param {String} $replace ('') - New value
// // @return {String} - Updated string
// @function str-replace($string, $search, $replace: "") {
//   $index: str-index($string, $search);

//   @if $index {
//     @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
//   }

//   @return $string;
// }

// // Color contrast
// @function color-yiq($color) {
//   $r: red($color);
//   $g: green($color);
//   $b: blue($color);

//   $yiq: (($r * 299) + ($g * 587) + ($b * 114)) / 1000;

//   @if ($yiq >= $yiq-contrasted-threshold) {
//     @return $yiq-text-dark;
//   } @else {
//     @return $yiq-text-light;
//   }
// }

// // Retrieve color Sass maps
// @function color($key: "blue") {
//   @return map-get($colors, $key);
// }

// @function theme-color($key: "primary") {
//   @return map-get($theme-colors, $key);
// }

// @function gray($key: "100") {
//   @return map-get($grays, $key);
// }

// // Request a theme color level
// @function theme-color-level($color-name: "primary", $level: 0) {
//   $color: theme-color($color-name);
//   $color-base: if($level > 0, #000, #fff);
//   $level: abs($level);

//   @return mix($color-base, $color, $level * $theme-color-interval);
// }


// z-indexes of various components all in one place so we can ensure the 
// correct stacking e.g. modals above everything.

// add site components that require z-index here 
$z-index: (
  // hp-course-search-suggestions    : 1000001,
  // navigation         : 100,
  // footer             : 90,
  // triangle           : 60,
  // navigation-rainbow : 50,
  // share-type         : 41,
  // share              : 40,
);

// use this function throughout the site to fetch z-indexes for each component
// e.g. z-index: z-index(hp-course-search-suggestions);
@function z-index($key) {
  @return map-get($z-index, $key);
}

// Slightly tint a color
// @access public
// @param {Color} $color - color to tint
// @param {Number} $percentage - percentage of `$color` in returned color
// @return {Color}
@function tint($color, $percentage) {
  @return mix(white, $color, $percentage);
}

// Slightly shade a color
// @access public
// @param {Color} $color - color to shade
// @param {Number} $percentage - percentage of `$color` in returned color
// @return {Color}
@function shade($color, $percentage) {
  @return mix(black, $color, $percentage);
}
