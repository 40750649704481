@import "../../node_modules/bootstrap/scss/modal";

// TODO move to modal.scss
/*******************************
* MODAL AS LEFT/RIGHT SIDEBAR
* Add "left" or "right" in modal parent div, after class="modal".
* Get free snippets on bootpen.com
*******************************/
.modal.left .modal-dialog,
.modal.right .modal-dialog {
    position: fixed;
    margin: auto;
    width: 320px;
    height: 100%;
    transform: translate3d(0%, 0, 0);
}

.modal.left .modal-content,
.modal.right .modal-content {
    height: 100%;
    overflow-y: auto;
}

.modal.left .modal-body,
.modal.right .modal-body {
    padding: 15px 15px;
}

/*Left*/
.modal.left.fade .modal-dialog {
    left: -320px;
    transition: opacity 0.3s linear, left 0.3s ease-out;
}

.modal.left.fade.show .modal-dialog{
    left: 0;
}
      
/*Right*/
.modal.right.fade .modal-dialog {
    right: -320px;
    transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.right.fade.show .modal-dialog {
    right: 0;
}

// /* ----- MODAL STYLE ----- */
// .modal-content {
//   border-radius: 0;
//   border: none;
// }

// .modal-header {
//   border-bottom-color: #EEEEEE;
//   background-color: #FAFAFA;
// }