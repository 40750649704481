 /* ==========================================================================
     News / events
     ========================================================================== */
    .news {max-width: 700px; margin-left: auto;  margin-right: auto;
    .news-title {margin-top:$margin*2!important;}  
     
    h2 a, h3 a {@extend %nmis-heading-h3; text-decoration: underline; 
    &:hover{@extend %linkhoverText;} } 
    .newssentence {padding: 0;}}
   
  .events-key {background-color:$gray-500; padding:$padding; width:100%; margin: 0 0 $margin*1.5 0;
    .fas {color:$gray-9000;  font-size: 1.5rem;} }
    .event-info {margin:$margin 0;    
    .fas {color:$gray-9000; font-size: 2rem; float:left; padding:$padding/2 $padding/2 $padding/2 0;}}
    .news:first-child {margin-top:0;}
    .news:last-child {margin-bottom:$margin*2;}
    /* xs = Extra small <576px. Max container width None (auto) */ 
   @include media-breakpoint-down(md) {     
    .news {padding:0 $padding;}  
   } 
 
   
   
