 /* ==========================================================================
     Partner Logos 
     ========================================================================== */
.partner-logos {  padding:$padding 10%;   
    .row {padding:$padding 0;}
   a img {height: auto!important; max-width: 150px; padding:0; }
h2 {@extend %nmis-heading-blue-h2; margin-bottom:$margin*1.5;}
hr {width:5%; border: 2px solid $blue-primary; }
   
	 
    /*lg = Large ≥992px. Max container width 960px.*/ 
   @include media-breakpoint-down(lg) {  
    a img  {padding: $padding;}
      }     
    }
    /* xs = Extra small <576px. Max container width None (auto) */
   @include media-breakpoint-down(sm) { 
     .partner-logos { padding:$padding $padding;   
    a img  {max-width: 250px;  }
    .row {padding: 0;}        
} 
}  