// Bootstrap

// Plan is to replace the CDN bootstrap with a custom build
// Keep all new stuff up here at the top, and will slowly
// phase out stuff below or bring it up here.

// Required
@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";

// Optional
// Import the node_module version if we don't need to overwrite any class styles.
// Otherwise, create a version here (.e.g ./card.scss), import the node_module file
// and overwrite any classes from within there.
@import "./functions";
@import "./variables";
@import "../../node_modules/bootstrap/scss/reboot";
@import "../../node_modules/bootstrap/scss/type";
@import "../../node_modules/bootstrap/scss/images";
@import "../../node_modules/bootstrap/scss/code";
@import "../../node_modules/bootstrap/scss/grid";
@import "../../node_modules/bootstrap/scss/tables";
@import "./forms";
@import "./buttons";
@import "../../node_modules/bootstrap/scss/transitions";
@import "../../node_modules/bootstrap/scss/dropdown";
// @import "../../node_modules/bootstrap/scss/button-group";
@import "../../node_modules/bootstrap/scss/input-group";
// @import "../../node_modules/bootstrap/scss/custom-forms";
@import "../../node_modules/bootstrap/scss/nav";
@import "./navbar";
@import "./card";
@import "./breadcrumb";
@import "./pagination";
// @import "../../node_modules/bootstrap/scss/badge";
@import "./jumbotron";
@import "./alert";
// @import "../../node_modules/bootstrap/scss/progress";
@import "../../node_modules/bootstrap/scss/media";
@import "../../node_modules/bootstrap/scss/list-group";
@import "../../node_modules/bootstrap/scss/close";
// @import "../../node_modules/bootstrap/scss/toasts";
@import "./modal";
// @import "../../node_modules/bootstrap/scss/tooltip";
// @import "../../node_modules/bootstrap/scss/popover";
@import "./carousel";
// @import "../../node_modules/bootstrap/scss/spinners";
@import "../../node_modules/bootstrap/scss/utilities";
@import "../../node_modules/bootstrap/scss/print";

// Import core Boostrap files
//@import "node_modules/bootstrap/scss/functions";
//@import "node_modules/bootstrap/scss/variables";
// @import "../../node_modules/bootstrap/scss/mixins";

// Import NMIS partials
@import "partials/base";
@import "partials/header";
@import "partials/footer";
// @import "partials/general";
// @import "partials/breadcrumb"; // TODO redo the nav object to render a BS bc
// @import "partials/contact";
// @import "partials/form"; // move to other forms partial and delete?
@import "partials/news-events"; // TODO delete after we redesign the news and events pages
@import "partials/partner-logos"; // redo html in about-us.html

// circular slider
@import "../js/modules/circular-slider/scss/_style.scss";




//
// Utils
//

$font-size-breakpoints: (
  xs: 0.8rem,
  small: 0.9rem,
  medium: 1rem,
  large: 1.1rem,
  xl: 1.5rem,
  xxl: 2rem,
) !default;

@each $name, $value in $font-size-breakpoints {
  .font-size-#{$name} {
    font-size: $value !important;
  }
}

.list-first-10-only li:nth-of-type(1n + 11) {
  display: none;
}

// body {
//   overflow-x: hidden;
// }

//
// Borders
//

// Create border- width classes e.g. border-md
@each $name, $value in $border-widths {
  .border-#{$name} {
    border-width: $value !important;
  }
}

@each $color, $value in $theme-colors {
  .border-color-#{$color} {
    border-color: $value !important;
  }
}

// guttering 

// .gx-4 > * {
//   border: 10px solid white;
// }

// @each $name, $value in $spacers {
//   .gx-#{$name} > * {
//     margin-left: $value !important;
//     margin-right: $value !important;
//   }
// }


//
// Blockquotes
//

figcaption {
  margin: 0 0 20px;
  padding: 15px 20px 15px 45px;
}

blockquote{
  display:block;
  // background: #fff;
  padding: 15px 20px 15px 45px;
  margin: 0 0 20px;
  position: relative;


  background: $gray-100;
  // padding: $spacer;
  
  // /*Font*/
  // font-family: Georgia, serif;
  // font-size: 14px;
  // line-height: 1.2;
  // color: #666;

  // /*Box Shadow - (Optional)*/
  // -moz-box-shadow: 2px 2px 15px #ccc;
  // -webkit-box-shadow: 2px 2px 15px #ccc;
  // box-shadow: 2px 2px 15px #ccc;

  // /*Borders - (Optional)*/
  // border-left-style: solid;
  // border-left-width: 15px;
  // border-right-style: solid;
  // border-right-width: 2px;    
}

blockquote::before{
  content: "\201C"; /*Unicode for Left Double Quote*/
  
  // /*Font*/
  // font-family: Georgia, serif;
  // font-size: 60px;
  // font-weight: bold;
  // color: #999;
  
  /*Positioning*/
  position: absolute;
  left: 5px;
  top:5px;

  font-family: "Font Awesome 5 Free";
  content: "\f10d";
  font-weight: bold;
  font-size: 2rem;
  
}

blockquote::after{
  /*Reset to make sure*/
  content: "";
}

// blockquote a{
//   text-decoration: none;
//   background: #eee;
//   cursor: pointer;
//   padding: 0 3px;
//   color: #c76c0c;
// }

// blockquote a:hover{
//  color: #666;
// }

// blockquote em{
//   font-style: italic;
// }

  /*Default Color Palette*/
blockquote.default { 
  border-left-color: #656d77;
  border-right-color: #434a53;  
}

// blockquote {
//   background: $gray-100;
//   padding: $spacer;
//   margin-left: 3rem;
//   display: block;
// }

// blockquote::before {
//   font-family: "Font Awesome 5 Free";
//   content: "\f10d";
//   font-weight: bold;
//   font-size: 2rem;
//   padding-right: $spacer/2;
//   display: block;
//   float: left;
// }

// // blockquote::after {
// //   font-family: "Font Awesome 5 Free";
// //   content: "\f10e";
// //   font-weight: bold;
// //   padding-left: $spacer/2;
// // }

// blockquote {
//   p:last-child {
//     margin-bottom: 0 !important;
//     padding-bottom: 0 !important;
//   }
// }

//
// Backgrounds with opacity
//

@mixin background-opacity($color, $opacity: 0.3) {
  // background: $color; /* The Fallback */
  background: rgba($color, $opacity);
}

// @mixin background-opacity-gradient($color, $opacity-start: 0.3, $opacity-end: 1) {
//   // background: $color; /* The Fallback */
//   background-image: linear-gradient(to bottom, rgba($color, $opacity-start), rgba($color, $opacity-end));
// }

@each $color, $value in $theme-colors {
  .bg-#{$color}-opacity {
    @include background-opacity($value, 0.9);
  }
}

@each $color, $value in $theme-colors {
  .bg-#{$color}-opacity-hover {
    background-color: transparent;
  }
  .bg-#{$color}-opacity-hover:hover {
    @include background-opacity($value, 0.9);
  }
  @include media-breakpoint-down(sm) {
    .bg-#{$color}-opacity-hover {
      @include background-opacity($value, 0.9);
    }
  }
}

// @each $color,
// $value in $theme-colors {
//   .bg-#{$color}-opacity-gradient {
//     @include background-opacity-gradient($value);
//   }
// }

// .bg-#{$color}-opacity-gradient {
//   @include background-opacity-gradient($value);
// }

// background-image: linear-gradient(to bottom, rgba(255,0,0,0), rgba(255,0,0,1));

//
// Suggestions
//

.suggestions {
  display: none;
  position: absolute;
  z-index: 1000001;
  background: #fff;
  padding-bottom: 0;
  width: 100%;
  text-align: left;
}

.suggestions__list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.suggestions__list li {
  background: white;
}

.suggestions__list li a {
  width: 100%;
  padding: 10px;
  display: block;
  color: #3e4765 !important;
}

.suggestions__list-item--selected {
  background: #eee !important;
}

//
// News ticker
//

.news-ticker {
  border-radius: 20px;
  border: 1px solid black;
  font-weight: bold;
  font-size: 90%;
}

.news-ticker-left-col {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  color: white;
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}

.news-ticker-right-col {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  position: relative;
}

.news-ticker-carousel {
  // position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  box-sizing: border-box;
  padding: 0 60px 0 20px;

  p {
    margin: 0;
  }
}

@include media-breakpoint-down(sm) {
  .news-ticker {
    display: none;
  }
}

//
// Grouped filters
//

.grouped-filters-headings {
  a {
    color: black;
    font-weight: bold;
    display: inline-block;
    margin-left: 5px;
    margin-right: 5px;
    border-bottom: 3px solid #ccc;

    &:hover {
      text-decoration: none;
    }

    &[aria-selected="true"] {
      border-bottom: 3px solid #368dbc;
      color: #3e4765;
    }
  }
}

.grouped-filters-content-tab[aria-hidden="true"] {
  display: none;
}

// Youtube video container

.youtube-video-container {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.youtube-video-container::after {
  display: block;
  content: "";
  padding-top: 57%;
}

.youtube-video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

// certain backgrounds should use white text
.bg-primary,
.bg-blue-secondary,
.bg-blue-third,
.bg-purple,
.bg-blue-secondary-900,
.bg-blue-secondary-800 {
  color: $white;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $white;
  }
}

// others should use dark 
.bg-light,
.bg-gray-100 { // TODO add this
  color: $blue-primary;

  h1 {
    color: $blue-primary;
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $purple;
  }
}

//
// Borders
//

// .border-top-orange {
//   border-top: 7px solid $orange-1000;
// }
// .border-top-green {
//   border-top: 7px solid $green;
// }
// .border-top-blue {
//   border-top: 7px solid $blue-third;
// }
// .border-top-purple {
//   border-top: 7px solid $purple;
// }

@each $color, $value in $theme-colors {
  .border-top-#{$color} {
    border-top: 7px solid color-yiq($value);
  }
}

@each $color, $value in $theme-colors {
  .border-#{$color} {
    border: 1px solid color-yiq($value);
  }
}

//
// Filters
//

.filter-buttons {
  // hide checkbox
  input[type="checkbox"],
  input[type="radio"] {
    position: absolute;
    left: -9999px;
  }

  // input[type='checkbox'] + .btn {
  //   margin-top: -3px;
  //   margin-left: -3px;
  //   box-shadow: $box-shadow-sm;
  // }

  // set selected state of buttons
  input[type="checkbox"],
  input[type="radio"] {
    &:checked {
      + .btn {
        color: $white;
        // margin-top: 0px;
        // margin-left: 0px;
        // box-shadow: none !important;
      }
    }
  }

  // input[data-color='secondary']:checked + .btn {
  //   background-color: map-get($theme-colors, 'secondary');
  // }
  // input[data-color='pink']:checked + .btn {
  //   background-color: map-get($theme-colors, 'pink');
  // }
  // input[data-color='purple']:checked + .btn {
  //   background-color: map-get($theme-colors, 'purple');
  // }
  // input[data-color='green']:checked + .btn {
  //   background-color: map-get($theme-colors, 'green');
  // }
  // input[data-color='blue-third']:checked + .btn {
  //   background-color: map-get($theme-colors, 'blue-third');
  // }

  @each $color, $value in $theme-colors {
    input[data-color="#{$color}"]:checked + .btn {
      background-color: $value;
    }
    input[data-color="#{$color}"]:focus + .btn {
      border: 5px solid tint($value, 50%);
    }
  }

  // disable hover state
  .btn:hover {
    background-color: white;
  }

  .btn-outline-secondary {
    color: $secondary;
  }

  .btn-outline-pink:hover {
    color: $pink;
  }

  .btn-outline-purple:hover {
    color: $purple;
  }

  .btn-outline-blue-third:hover {
    color: $blue-third;
  }

  .btn-outline-green:hover {
    color: $green;
  }
}

//
// Overlay
//

$overlay-border-width: 7px;

.overlay {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;

  &.overlay-hover-effect {
    margin: -$overlay-border-width;
    width: calc(100% + (#{$overlay-border-width} * 2)) !important;
    height: calc(100% + (#{$overlay-border-width} * 2)) !important;

    &:hover {
      border: $overlay-border-width solid $gray-200;
    }
  }

  &.overlay-shadow-effect {
    &:hover {
      box-shadow: 3px 3px 3px #c5c7c6 !important;
    }
  }

  &.overlay-hover-card-body-gold {
    &:hover {
      + .card-body {
        background: $gold !important;
        color: black !important;
  
        h3 {
          color: black !important;
        }
      }
    }
  }
}

@each $color, $value in $theme-colors {
  .overlay-#{$color} {
    background-color: $value;
    opacity: 0.8;
  }
}

.slick-prev {
  left: -30px !important;
}

.slick-dots li button:before {
  font-size: 10px !important;
}

.slick-prev:before,
.slick-next:before {
  color: $gray !important;
  font-size: 30px !important;
}

// slick carousel
.bg-blue-secondary {
  .slick-dots li button:before {
    color: $white !important;
  }

  .slick-prev:before,
  .slick-next:before {
    color: $white !important;
  }
}

.ellipsis {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/********** Theme: orange **********/
/* Font styles */
.flipdown.flipdown__theme-orange {
  font-family: sans-serif;
  font-weight: bold;
}
/* Rotor group headings */
.flipdown.flipdown__theme-orange .rotor-group-heading:before {
  color: $white;
}
/* Delimeters */
.flipdown.flipdown__theme-orange .rotor-group:nth-child(n + 2):nth-child(-n + 3):before,
.flipdown.flipdown__theme-orange .rotor-group:nth-child(n + 2):nth-child(-n + 3):after {
  background-color: $orange-1000;
}
/* Rotor tops */
.flipdown.flipdown__theme-orange .rotor,
.flipdown.flipdown__theme-orange .rotor-top,
.flipdown.flipdown__theme-orange .rotor-leaf-front {
  color: $white;
  background-color: $orange-1000;
}
/* Rotor bottoms */
.flipdown.flipdown__theme-orange .rotor-bottom,
.flipdown.flipdown__theme-orange .rotor-leaf-rear {
  color: $light;
  background-color: $orange;
}
/* Hinge */
.flipdown.flipdown__theme-orange .rotor:after {
  border-top: solid 1px $orange-1000;
}

// TIMELINE

// @import url('https://fonts.googleapis.com/css?family=Cardo|Pathway+Gothic+One');
// Responsive
@mixin responsive() {
  @media only screen and (max-width: 767px) {
    @content;
  }
}

// $content-font: Cardo;
// $heading-font: 'Pathway Gothic One', sans-serif;
$timeline-width: 700px;
$timeline-container-width: 100%;
.timeline {
  display: flex;
  margin: 0 auto;
  flex-wrap: wrap;
  flex-direction: column;
  max-width: $timeline-width;
  position: relative;
  &__content {
    &-title {
      // font-weight: normal;
      // font-size: 66px;
      margin: -10px 0 0 0;
      transition: 0.4s;
      // padding: 0 10px;
      box-sizing: border-box;
      // font-family: $heading-font;
      color: $gold;
      text-transform: uppercase;
    }
    &-desc {
      margin: 0;
      font-size: 15px;
      box-sizing: border-box;
      // color: rgba(#fff, .7);
      color: $white;
      // font-family: $content-font;
      font-weight: normal;
      line-height: 25px;
    }
  }
  &:before {
    position: absolute;
    left: 50%;
    width: 2px;
    height: 100%;
    margin-left: -1px;
    content: "";
    background: rgba(255, 255, 255, 0.07);
    @include responsive() {
      left: 40px;
    }
  }
  &-item {
    padding: 40px 0;
    opacity: 0.3;
    filter: blur(2px);
    transition: 0.5s;
    box-sizing: border-box;
    width: calc(50% - 40px);
    display: flex;
    position: relative;
    transform: translateY(-80px);
    &:before {
      content: attr(data-text);
      letter-spacing: 3px;
      width: 100%;
      position: absolute;
      // color: rgba(#fff, .5);
      color: $white;
      font-size: 13px;
      // font-family: $heading-font;
      // border-left: 2px solid rgba(#fff, .5);
      text-transform: uppercase;
      top: 70%;
      margin-top: -5px;
      padding-left: 15px;
      opacity: 0;
      right: calc(-100% - 56px);
    }
    &:nth-child(even) {
      align-self: flex-end;
      &:before {
        right: auto;
        text-align: right;
        left: calc(-100% - 56px);
        padding-left: 0;
        border-left: none;
        text-transform: uppercase;
        // border-right: 2px solid rgba(#fff, .5);
        padding-right: 15px;
      }
    }
    &--active {
      opacity: 1;
      transform: translateY(0);
      filter: blur(0px);
      &:before {
        top: 50%;
        transition: 0.3s all 0.2s;
        opacity: 1;
      }
      .timeline__content-title {
        margin: -50px 0 20px 0;
      }
    }
    @include responsive() {
      align-self: baseline !important;
      width: 100%;
      padding: 0 30px 150px 80px;
      &:before {
        left: 10px !important;
        padding: 0 !important;
        top: 50px;
        text-align: center !important;
        width: 60px;
        border: none !important;
      }
      &:last-child {
        padding-bottom: 40px;
      }
    }
  }
  &__img {
    max-width: 100%;
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.4);
  }

  &__img-wrapper {
    position: relative;
    padding-top: 10px;
    padding-left: 10px;

    &::before {
      content: " ";
      position: absolute;
      width: 10px;
      bottom: 10px;
      left: 0;
      top: 0;
      background: $gold;
    }

    &::after {
      content: " ";
      position: absolute;
      height: 10px;
      right: 10px;
      left: 0;
      top: 0;
      background: $gold;
    }
  }
  &-container {
    width: $timeline-container-width;
    position: relative;
    padding: 80px 0;
    transition: 0.3s ease 0s;
    background-attachment: fixed;
    background-size: cover;
    &:before {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      // background: rgba(99, 99, 99, 0.8);
      background: rgba($blue-secondary, 0.8);
      content: "";
    }
  }
  &-header {
    width: 100%;
    text-align: center;
    margin-bottom: 80px;
    position: relative;
    &__title {
      color: #fff;
      font-size: 46px;
      // font-family: $content-font;
      font-weight: normal;
      margin: 0;
    }
    &__subtitle {
      color: rgba(255, 255, 255, 0.5);
      // font-family: $heading-font;
      font-size: 16px;
      letter-spacing: 5px;
      margin: 10px 0 0 0;
      font-weight: normal;
    }
  }
}

// // DEMO
// .demo-footer {
//   padding: 60px 0;
//   text-align: center;
//   a {
//     color: #999;
//     display: inline-block;
// font-family: $content-font;
//   }
// }
.img-border-top-left-bottom-right-radius {
  border-top-left-radius: 100px;
  border-bottom-right-radius: 100px;
}

.h-underline,
.h-underline-center {
  position: relative;
  padding-bottom: 15px !important;
  margin-bottom: 15px !important;
}

.h-underline:after {
  content: "";
  width: 100px;
  height: 3px;
  background: $blue-third;
  position: absolute;
  bottom: -4px;
  left: 0;
}

.h-underline-center:after {
  content: "";
  height: 3px;
  // background: $blue-third;
  position: absolute;
  bottom: -4px;
  width: 100px;
  left: 50%;
  margin-left: -50px;
}

@each $color, $value in $theme-colors {
  .h-underline-#{$color}:after {
    background: $value;
  }
}

// Misc 

.pointer-events-none {
  pointer-events: none;
}





@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?3zjrns');
  src:  url('fonts/icomoon.eot?3zjrns#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?3zjrns') format('truetype'),
    url('fonts/icomoon.woff?3zjrns') format('woff'),
    url('fonts/icomoon.svg?3zjrns#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  // sizing - using fa styles so we can use them along side fa icons
  &.fa-xs {
    font-size: .75em;
  }
  &.fa-sm {
    font-size: .875em;
  }
  &.fa-lg {
    font-size: 1.33333em;
    line-height: .75em;
    vertical-align: -0.0667em;
  }
  &.fa-2x {
    font-size: 2em;
  }
  &.fa-3x {
    font-size: 3em;
  }
  &.fa-5x {
    font-size: 5em;
  }
  &.fa-7x {
    font-size: 7em;
  }
  &.fa-10x {
    font-size: 10em;
  }
}

.icon-aeroplane:before {
  content: "\e911";
}
.icon-circular-economy:before {
  content: "\e912";
}
.icon-hardhat-green:before {
  content: "\e913";
}
.icon-photonic:before {
  content: "\e914";
}
.icon-ship:before {
  content: "\e915";
}
.icon-wind-turbine:before {
  content: "\e916";
}
.icon-mechanical-arm:before {
  content: "\e90f";
}
.icon-graph:before {
  content: "\e901";
}
// .icon-hardhat .path1:before {
//   content: "\e902";
//   color: rgb(29, 29, 27);
//   opacity: 0.94;
// }
// .icon-hardhat .path2:before {
//   content: "\e903";
//   margin-left: -1.44140625em;
//   color: rgb(29, 29, 27);
//   opacity: 0.94;
// }
// .icon-hardhat .path3:before {
//   content: "\e904";
//   margin-left: -1.44140625em;
//   color: rgb(29, 29, 27);
//   opacity: 0.94;
// }
// .icon-hardhat .path4:before {
//   content: "\e905";
//   margin-left: -1.44140625em;
//   color: rgb(29, 29, 27);
// }
// .icon-hardhat .path5:before {
//   content: "\e906";
//   margin-left: -1.44140625em;
//   color: rgb(29, 29, 27);
// }
.icon-laptop:before {
  content: "\e907";
}
.icon-materials:before {
  content: "\e908";
}
.icon-vr-headset:before {
  content: "\e909";
}
.icon-attract-talent:before {
  content: "\e90a";
}
.icon-economy-growth:before {
  content: "\e90b";
}
.icon-increase-productivity:before {
  content: "\e90c";
}
.icon-job-creation:before {
  content: "\e90d";
}
.icon-net-zero:before {
  content: "\e90e";
}