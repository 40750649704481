// Variables

// Color system

//Grays Primary
$gray: #6f7271;
$gray-100: tint($gray, 90%);
$gray-200: tint($gray, 80%);
$gray-300: tint($gray, 70%);
$gray-400: tint($gray, 60%);
$gray-500: tint($gray, 50%);
$gray-600: tint($gray, 40%);
$gray-700: tint($gray, 30%);
$gray-800: tint($gray, 20%);
$gray-900: tint($gray, 10%);
$gray-7000: shade($gray, 10%);
$gray-8000: shade($gray, 20%);
$gray-9000: shade($gray, 30%);

// Blues Primary
$blue-primary: #3e4765;
$blue-primary-100: tint($blue-primary, 90%);
$blue-primary-200: tint($blue-primary, 80%);
$blue-primary-300: tint($blue-primary, 70%);
$blue-primary-400: tint($blue-primary, 60%);
$blue-primary-500: tint($blue-primary, 50%);
$blue-primary-600: tint($blue-primary, 40%);
$blue-primary-700: tint($blue-primary, 30%);
$blue-primary-800: tint($blue-primary, 20%);
$blue-primary-900: tint($blue-primary, 10%);
$blue-primary-9000: shade($blue-primary, 10%);
$blue-primary-8000: shade($blue-primary, 20%);

// Blues Secondary
$blue-secondary: #00294b;
$blue-secondary-100: tint($blue-secondary, 90%);
$blue-secondary-200: tint($blue-secondary, 80%);
$blue-secondary-300: tint($blue-secondary, 70%);
$blue-secondary-400: tint($blue-secondary, 60%);
$blue-secondary-500: tint($blue-secondary, 50%);
$blue-secondary-600: tint($blue-secondary, 40%);
$blue-secondary-700: tint($blue-secondary, 30%);
$blue-secondary-800: tint($blue-secondary, 20%);
$blue-secondary-900: tint($blue-secondary, 10%);

// Blues Third
$blue-third: #368dbc;
$blue-third-100: tint($blue-third, 90%);
$blue-third-200: tint($blue-third, 80%);
$blue-third-300: tint($blue-third, 70%);
$blue-third-400: tint($blue-third, 60%);
$blue-third-500: tint($blue-third, 50%);
$blue-third-600: tint($blue-third, 40%);
$blue-third-700: tint($blue-third, 30%);
$blue-third-800: tint($blue-third, 20%);
$blue-third-900: tint($blue-third, 10%);

// Blues Fourth
$blue-fourth: #009fe3;
$blue-fourth-100: tint($blue-fourth, 90%);
$blue-fourth-200: tint($blue-fourth, 80%);
$blue-fourth-300: tint($blue-fourth, 70%);
$blue-fourth-400: tint($blue-fourth, 60%);
$blue-fourth-500: tint($blue-fourth, 50%);
$blue-fourth-600: tint($blue-fourth, 40%);
$blue-fourth-700: tint($blue-fourth, 30%);
$blue-fourth-800: tint($blue-fourth, 20%);
$blue-fourth-900: tint($blue-fourth, 10%);

// Greens
$green: #8ec89a;
$green-100: tint($green, 90%);
$green-200: tint($green, 80%);
$green-300: tint($green, 70%);
$green-400: tint($green, 60%);
$green-500: tint($green, 50%);
$green-600: tint($green, 40%);
$green-700: tint($green, 30%);
$green-800: tint($green, 20%);
$green-900: tint($green, 10%);
$green-9000: shade($green, 10%);
$green-8000: shade($green, 20%);

// Pinks
$pink: #d83569;
$pink-100: tint($pink, 90%);
$pink-200: tint($pink, 80%);
$pink-300: tint($pink, 70%);
$pink-400: tint($pink, 60%);
$pink-500: tint($pink, 50%);
$pink-600: tint($pink, 40%);
$pink-700: tint($pink, 30%);
$pink-800: tint($pink, 20%);
$pink-900: tint($pink, 10%);
$pink-1000: shade($pink, 10%);
$pink-2000: shade($pink, 20%);
$pink-3000: shade($pink, 30%);

//Oranges
$orange: #ec6608;
$orange-100: tint($orange, 90%);
$orange-200: tint($orange, 80%);
$orange-300: tint($orange, 70%);
$orange-400: tint($orange, 60%);
$orange-500: tint($orange, 50%);
$orange-600: tint($orange, 40%);
$orange-700: tint($orange, 30%);
$orange-800: tint($orange, 20%);
$orange-900: tint($orange, 10%);
$orange-1000: shade($orange, 10%);
$orange-2000: shade($orange, 20%);
$orange-3000: shade($orange, 30%);

//Golds
$gold: #ffcc00;
$gold-100: tint($gold, 90%);
$gold-200: tint($gold, 80%);
$gold-300: tint($gold, 70%);
$gold-400: tint($gold, 60%);
$gold-500: tint($gold, 50%);
$gold-600: tint($gold, 40%);
$gold-700: tint($gold, 30%);
$gold-800: tint($gold, 20%);
$gold-900: tint($gold, 10%);

//Purples
$purple: #6f3f81;
$purple-100: tint($purple, 90%);
$purple-200: tint($purple, 80%);
$purple-300: tint($purple, 70%);
$purple-400: tint($purple, 60%);
$purple-500: tint($purple, 50%);
$purple-600: tint($purple, 40%);
$purple-700: tint($purple, 30%);
$purple-800: tint($purple, 20%);
$purple-900: tint($purple, 10%);
$purple-1000: shade($purple, 10%);

// additional nmis colours not included in the default bootstrap colours names
$colors: map-merge(
  $colors,
  (
    "blue": $blue-primary,
    "blue-secondary": $blue-secondary,
    "blue-third": $blue-third,
    "blue-fourth": $blue-fourth,
    "gold": $gold,
  )
);

// These are used a lot with custom classes (e.g. border-top-purple)
// In many cases, to add a class such as border-top-purple-variation, add "purple-variation"
// to this array.
$theme-colors: map-merge(
  $theme-colors,
  (
    "primary": $blue-primary,
    "secondary": $gray,
    "blue-secondary": $blue-secondary,
    "blue-secondary-900": $blue-secondary-900,
    "blue-secondary-800": $blue-secondary-800,
    "blue-secondary-100": $blue-secondary-100,
    "blue-third": $blue-third,
    "danger": $pink,
    "pink": $pink,
    "green": $green,
    "green-8000": $green-8000,
    "purple": $purple,
    "gray-100": $gray-100,
    "gray-200": $gray-200,
    "orange": $orange,
    "black": $black,
    "white": $white,
    "purple-100": $purple-100,
    "orange-100": $orange-100,
    "pink-100": $pink-100,
    "green-100": $green-100,
    "gold": $gold,
  )
);


// Spacers 

$spacers: map-merge(
  (
    // 0: 0,
    // 1: ($spacer * .25),
    // 2: ($spacer * .5),
    // 3: $spacer,
    // 4: ($spacer * 1.5),
    // 5: ($spacer * 3),
    6: ($spacer * 5),
    7: ($spacer * 7)
  ),
  $spacers
);




// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-rounded: false;

// 
// Buttons
// 

$btn-border-width: 2px;

// 
// Breadcrumbs
// 

$breadcrumb-margin-bottom: 0;
$breadcrumb-divider: quote(">");

// Components
//
// Define common padding and border radius sizes and more.

$box-shadow-sm: 3px 3px 3px $gray-400;

//
// Dropdown
//

$dropdown-link-color: $white;
$dropdown-link-hover-color: $white;
$dropdown-link-hover-bg: $blue-primary-900;
$dropdown-link-active-bg: $blue-primary-900;
$dropdown-bg: $blue-primary;
$dropdown-min-width: 325px;
$dropdown-padding-y: 0;



// Alerts
//
// Define alert colors, border radius, and padding.

$alert-border-width: 3px;
$alert-border-level: 0;



// 
// Card 
// 

$card-spacer-x: 1.25rem;
$card-spacer-y: 1rem;



// Jumbotron 

$jumbotron-bg: $blue-secondary;
$jumbotron-color: $white;


// 
// Pagination 
// 
$pagination-color: $blue-secondary;
$pagination-active-bg: $blue-secondary;
$pagination-active-border-color: $blue-secondary;




// Custom variables

// 
// Borders 
// 

$border-widths: (
  "xs": 1px, 
  "sm": 3px, 
  "md": 5px, 
  "lg": 7px, 
  "xl": 9px
);

// generate the %border-top- mixins
@each $color, $value in $theme-colors {
  %border-top-#{$color} {
    border-top: 7px solid $value !important;
  }
}

// // generate the %border- mixins
// @each $color, $value in $theme-colors {
//   %border-#{$color} {
//     border: 1px solid $value !important;
//   }
// }









//padding & margin
$padding: 20px;
$margin: 20px;
//Hovers

$hover: $pink;

//Hover state for heading background
%linkhover {
  background-color: $white;
  color: $hover;
  text-decoration: none;
}

//Hover state for link within text
%linkhoverText {
  color: $hover;
  text-decoration: none;
}
//Bulleted list
// %bullets-logo { background: url('../../images/nmis-bullet.png') no-repeat 5px 0 transparent;
//   list-style-type: none;
//   margin: 0;
//   padding: 0 0 $padding $padding*1.5;
//   vertical-align: middle;}

%bullets {
  list-style: none;
}

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

// $spacer: 1rem;
// $spacers: ();
// // stylelint-disable-next-line scss/dollar-variable-default
// $spacers: map-merge(
//   (
//     0: 0,
//     1: ($spacer * .25),
//     2: ($spacer * .5),
//     3: $spacer,
//     4: ($spacer * 1.5),
//     5: ($spacer * 3)

//   ),
//   $spacers
// );

// // Body
// //
// // Settings for the `<body>` element.

// $body-bg:                   $white;
// $body-color:                $gray-900;

// // Style p element.

// $paragraph-margin-bottom:   1rem;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px,
);

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 1140px,
  md: 1140px,
  lg: 1140px,
  xl: 1140px,
  xxl: 1140px,
);

//@include _assert-ascending($container-max-widths, "$container-max-widths");

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

// $grid-columns:                12;
// $grid-gutter-width:           30px;

// Components
//
// Define common padding and border radius sizes and more.

// $line-height-lg:              1.5;
// $line-height-sm:              1.5;

// $border-width:                1px;
// $border-color:                $gray-300;

// TODO $enable-rounded:                              false !default;
$border-radius: 0;
$border-radius-lg: 0;
$border-radius-sm: 0;

// $box-shadow-sm:               0 .125rem .25rem rgba($black, .075);
// $box-shadow:                  0 .5rem 1rem rgba($black, .15);
// $box-shadow-lg:               0 1rem 3rem rgba($black, .175);

// $component-active-color:      $white;
// $component-active-bg:         theme-color("primary");

// $caret-width:                 .3em;

// $transition-base:             all .2s ease-in-out;
// $transition-fade:             opacity .15s linear;
// $transition-collapse:         height .35s ease;

// Fonts
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
$font-family-sans-serif: "Raleway", sans-serif;
// $font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
$font-family-base: $font-family-sans-serif;
// stylelint-enable value-keyword-case

// $font-size-base:              1.07rem; // Assumes the browser default, typically `16px`
// $font-size-lg:                ($font-size-base * 1.25);
// $font-size-sm:                ($font-size-base * .875);

// $font-weight-light:           300;
// $font-weight-normal:          400;
// $font-weight-bold:            700;

// $font-weight-base:            $font-weight-normal;
// $line-height-base:            1.5;

//nmis Headings

$nmis-heading-color: $blue-primary;
$nmis-heading-weight: $font-weight-bold;
$nmis-font-normal: $gray-700;
$h1-font-size: $font-size-base * 2.5;
$h2-font-size: $font-size-base * 1.75;
$h3-font-size: $font-size-base * 1.25;
$h4-font-size: $font-size-base * 1;
$h5-font-size: $font-size-base;
$h6-font-size: $font-size-base;

%nmis-heading-h1 {
  color: $blue-primary !important;
  font-weight: bold !important;
  font-size: $h1-font-size;
}
%nmis-heading-h2 {
  color: $purple;
  font-weight: bold !important;
  font-size: $h2-font-size;
  margin-top: $margin/1.5 !important;
}
%nmis-heading-h3 {
  color: $purple;
  font-weight: bold !important;
  font-size: $h3-font-size;
  margin-top: $margin/1.5 !important;
}
%nmis-heading-h4 {
  color: $purple;
  font-weight: bold !important;
  font-size: $h4-font-size;
  margin-top: $margin/1.5 !important;
}
%nmis-heading-h5 {
  color: $purple;
  font-weight: bold !important;
  font-size: $h5-font-size;
  margin-top: $margin/1.5 !important;
}

%nmis-heading-blue-h2 {
  color: $blue-primary;
  font-weight: bold;
  font-size: $h2-font-size;
  margin-top: $margin/1.5 !important;
}
%nmis-heading-blue-h3 {
  color: $blue-primary;
  font-weight: bold;
  font-size: $h3-font-size;
  margin-top: $margin/1.5 !important;
}
%nmis-heading-blue-h4 {
  color: $blue-primary;
  font-weight: bold;
  font-size: $h4-font-size;
  margin-top: $margin/1.5 !important;
}
// %nmis-heading-blue-h5 { color:$blue; font-weight:bold; font-size:$h5-font-size; margin-top: $margin/1.5!important;}

%nmis-heading-white {
  color: $white;
  font-weight: bold;
}
$icon-font: "Font Awesome";


// Navbar 

$navbar-light-color: rgba($black, .7);